import React, { useCallback, useEffect } from 'react';
import { Button, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Types.module.scss';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import { useActions } from '../../../hooks/useActions';
import FormInput from '../../../components/FormItems/FormInput';
import { RolesInterceptor } from '../../../components/Interceptors/RolesInterceptor';
import { createTree } from '../../../helpers/selectHelper';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import FormTreeSelect from '../../../components/FormItems/FormTreeSelect';
import { statusNewsOptions } from '../../../helpers/newsHelper';
import { useTrimField } from '../../../hooks/useTrimField';
import { SUBMIT_METHOD_ADD, ROLES } from '../../../types/constants';

interface TypesProps {
  titlePage: string;
  submitMethod: string;
}

export const Types: React.FC<TypesProps> = ({ titlePage, submitMethod }) => {
  const navigate = useNavigate();
  const { categories } = useTypedSelector((state) => state.category);
  const categoryTree = createTree(categories);
  const { fetchCategoriesForSelect } = useActions();

  const { id } = useParams();
  const { request } = useHttp();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchTypes = await request({
          url: `${ApiUrl.TYPE}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          name: fetchTypes.data?.name,
          categoryId: fetchTypes.data?.categoryId,
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchCategoriesForSelect();
    getData();
    if (!id) {
      form.setFieldsValue({
        status: statusNewsOptions[0],
      });
    }
  }, []);

  const onFinish = async (e: any) => {
    try {
      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? {
              url: ApiUrl.TYPE,
              method: 'post',
              body: {
                ...e,
              },
            }
          : {
              url: `${ApiUrl.TYPE}/${id}`,
              method: 'put',
              body: {
                ...e,
              },
            };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/types/list');
          return NotificationAlert('success', 'Тип добавлен');
        case 'put':
          return NotificationAlert('success', 'Тип обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      return NotificationAlert(
        'error',
        err.response.data?.message || err.message,
      );
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <RolesInterceptor roles={[ROLES.admin, ROLES.content]}>
          <FormInput
            name={'name'}
            label={'Название'}
            placeholder={'Введите Название'}
            required={true}
            labelCol={{ span: 24 }}
            maxLength={255}
          />
          <FormTreeSelect
            required={true}
            showSearch={true}
            name={'categoryId'}
            label={'Категория'}
            placeholder={'Выберите категорию'}
            treeData={categoryTree}
            labelCol={{ span: 24 }}
          />
        </RolesInterceptor>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
