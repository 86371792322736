import React, { useCallback, useEffect } from 'react';
import { Button, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Role.module.scss';
import { transliterate } from '../../../helpers/productHelper';
import { statusProducerOptions } from '../../../helpers/producerHelper';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import { useTrimField } from '../../../hooks/useTrimField';
import { SUBMIT_METHOD_ADD } from '../../../types/constants';

interface ProducerProps {
  titlePage: string;
  submitMethod: string;
}

export const Role: React.FC<ProducerProps> = ({ titlePage, submitMethod }) => {
  const { value } = useParams();
  const { request } = useHttp();

  const navigate = useNavigate();

  const getData = useCallback(async () => {
    if (value) {
      try {
        const fetcRole = await request({
          url: `${ApiUrl.ROLE}/${value}`,
          method: 'get',
        });

        form.setFieldsValue({
          value: fetcRole.data?.value,
          description: fetcRole.data?.description,
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    if (!value) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, [getData]);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? { url: ApiUrl.ROLE, method: 'post', body: e }
          : { url: `${ApiUrl.ROLE}/${value}`, method: 'put', body: e };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/roles/list');
          return NotificationAlert('success', 'Роль добавлена');
        case 'put':
          return NotificationAlert('success', 'Роль обновлена');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <FormInput
          name={'value'}
          label={'Значение'}
          required={true}
          placeholder={'Значение роли'}
        />
        <FormInput
          name={'description'}
          label={'Описание'}
          placeholder={'Описание роли'}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
