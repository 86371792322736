import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Card, Col, Form, Popconfirm, Row, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import styles from './Attribute.module.scss';
import { ApiUrl } from '../../../types/ApiUrl';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { IAttribute } from '../../../types/entities/Attribute/Attribute';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { SelectOptionHelper } from '../../../helpers/selectHelper';
import { useActions } from '../../../hooks/useActions';
import { typeAttributeOptions } from '../../../helpers/attributeHelper';
import NotificationAlert from '../../../components/Notification';
import FormSelect from '../../../components/FormItems/FormSelect';
import FormInput from '../../../components/FormItems/FormInput';
import FormCheckbox from '../../../components/FormItems/FormCheckbox';
import { SUBMIT_METHOD_ADD } from '../../../types/constants';

interface AttributeProps {
  titlePage: string;
  submitMethod: string;
}

export const Attribute: React.FC<AttributeProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { attributeGroups } = useTypedSelector((state) => state.attributeGroup);
  const { types } = useTypedSelector((state) => state.type);

  const navigate = useNavigate();

  const { fetchAttributeGroupsForSelect, fetchTypes } = useActions();

  const typeProductOptions = SelectOptionHelper(types);
  const attributeGroupsOptions = SelectOptionHelper(attributeGroups);

  const { id } = useParams();
  const { request } = useHttp();

  const [attribute, setAttribute] = useState<IAttribute>();
  const [type, setType] = useState<number>();

  const [form] = Form.useForm();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchAttribute = await request({
          url: `${ApiUrl.ATTRIBUTE}/${id}`,
          method: 'get',
        });
        setAttribute(fetchAttribute.data);

        form.setFieldsValue({
          type: fetchAttribute.data?.type,
          groupId: fetchAttribute.data?.groupId,
          title: fetchAttribute.data?.title,
          smaleName: fetchAttribute.data?.smaleName,
          name: fetchAttribute.data?.name,
          description: fetchAttribute.data?.description,
          unit: fetchAttribute.data?.unit,
          sort: fetchAttribute.data?.sort,
          sortFilters: fetchAttribute.data?.sortFilters,
          typeProduct: fetchAttribute.data.typeProduct.map((el: any) => {
            return { value: el.id, label: el.name };
          }),
          isFilter: Boolean(fetchAttribute.data?.isFilter),
          onec: Boolean(fetchAttribute.data?.onec),
          required: Boolean(fetchAttribute.data?.required),
          showInTitle: Boolean(fetchAttribute.data?.showInTitle),
        });
      } catch (e) {}
    }
  }, [request]);

  const addAttributeOption = async (value: string) => {
    if (form.getFieldValue('attributeOption') !== '') {
      try {
        await upload(form.getFieldsValue);
        await request({
          url: `${ApiUrl.ADD_ATTRIBUTE_OPTION}`,
          method: 'post',
          body: { attributeId: id, value: value },
        });
        await getData();
        return NotificationAlert('success', 'Опция добавлена');
      } catch (e) {}
    }
  };

  const deleteAttributeOption = async (id: number) => {
    try {
      await request({
        url: `${ApiUrl.DELETE_ATTRIBUTE_OPTION}/${id}`,
        method: 'delete',
      });
      await getData();
      return NotificationAlert('success', 'Опция удалена');
    } catch (e) {}
  };

  useEffect(() => {
    getData();
    fetchAttributeGroupsForSelect();
    fetchTypes();
  }, [getData]);

  useEffect(() => {
    form.setFieldValue('typeProduct', []);
  }, []);

  const upload = async (e: any) => {
    try {
      delete e.attributeOption;
      e.type = type;
      e.typeProduct = e.typeProduct.map((item: any) =>
        typeof item === 'number' ? { value: item } : item,
      );
      if (form.getFieldValue('sort') !== '') {
        e.sort = form.getFieldValue('sort');
      }
      if (form.getFieldValue('sortFilters') !== '') {
        e.sortFilters = form.getFieldValue('sortFilters');
      }
      if (form.getFieldValue('required')) {
        e.required = 1;
      } else {
        e.required = 0;
      }

      if (form.getFieldValue('isFilter')) {
        e.isFilter = 1;
      } else {
        e.isFilter = 0;
      }

      if (form.getFieldValue('onec')) {
        e.onec = 1;
      } else {
        e.onec = 0;
      }
      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? { url: ApiUrl.ATTRIBUTE, method: 'post', body: e }
          : { url: `${ApiUrl.ATTRIBUTE}/${id}`, method: 'put', body: e };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/attributes/attributes/list');
          return NotificationAlert('success', 'Атрибут добавлен');
        case 'put':
          return NotificationAlert('success', 'Атрибут обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  const OptionsColumns = [
    {
      title: 'Значение',
      key: 'value',
      width: '70%',
      render: (data: any) => {
        if (data) {
          return <>{data.value}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Удалить опцию',
      key: 'delete',
      width: '30%',
      render: (data: any) => {
        return (
          <Button type="link" style={{ color: 'inherit' }}>
            <Popconfirm
              title={'Вы точно хотите удалить опцию?'}
              onConfirm={() => deleteAttributeOption(data.id)}
            >
              <DeleteOutlined style={{ cursor: 'pointer', fontSize: 22 }} />
            </Popconfirm>
          </Button>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form form={form} onFinish={upload}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormSelect
              name={'type'}
              label={'Тип'}
              placeholder={'тип'}
              options={typeAttributeOptions}
              required={true}
              showSearch={true}
              labelCol={{ span: 24 }}
              onChange={(value: number) => setType(value)}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              name={'groupId'}
              label={'Группа атрибутов'}
              placeholder={'группа атрибутов'}
              options={attributeGroupsOptions}
              required={true}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormInput
              name={'title'}
              label={'Название'}
              placeholder={'Название'}
              labelCol={{ span: 24 }}
              maxLength={250}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name={'smaleName'}
              label={'Короткое название'}
              placeholder={'Короткое название'}
              labelCol={{ span: 24 }}
              maxLength={200}
            />
          </Col>
        </Row>
        <FormInput
          name={'name'}
          label={'Name'}
          placeholder={'name'}
          labelCol={{ span: 24 }}
          maxLength={250}
        />
        <FormInput
          name={'description'}
          label={'Описание'}
          placeholder={'описание'}
          textArea={true}
          labelCol={{ span: 24 }}
        />
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormInput
              name={'unit'}
              label={'Единица измерения'}
              placeholder={'единица измерения'}
              labelCol={{ span: 24 }}
              maxLength={30}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormInput
              name={'sort'}
              label={'Sort'}
              placeholder={'sort'}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name={'sortFilters'}
              label={'Sort Filters'}
              placeholder={'sortFilters'}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        {form.getFieldValue('type') &&
        (form.getFieldValue('type').toString() === '2' ||
          form.getFieldValue('type').toString() === '4') ? (
          submitMethod === SUBMIT_METHOD_ADD ? (
            <Alert
              message="Чтобы добавить опции атрибута, после создания атрибута зайдите в его обновление"
              type="info"
            />
          ) : (
            <Card
              style={{ marginTop: 16, textAlign: 'left' }}
              type="inner"
              title="Опции атрибутов"
            >
              <Table
                columns={OptionsColumns}
                dataSource={attribute?.options}
                pagination={false}
                rowKey="id"
              />
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{ marginTop: 20 }}
              >
                <Col span={20}>
                  <FormInput
                    name={'attributeOption'}
                    placeholder={'введите опцию атрибута'}
                  />
                </Col>
                <Col span={4} style={{ padding: 0 }}>
                  <Button
                    style={{
                      backgroundColor: '#428bca',
                      color: '#fff',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      addAttributeOption(form.getFieldValue('attributeOption'))
                    }
                  >
                    Добавить
                  </Button>
                </Col>
              </Row>
            </Card>
          )
        ) : undefined}
        <FormSelect
          name={'typeProduct'}
          label={'Использовать в следующих типах товаров'}
          placeholder={'Выберите тип товара'}
          options={typeProductOptions}
          mode={'multiple'}
          showSearch={true}
          labelCol={{ span: 24 }}
        />
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            margin: 0,
          }}
        >
          <FormCheckbox
            name={'required'}
            checked={form.getFieldValue('required')}
            text={'Required'}
          />
          <FormCheckbox
            name={'isFilter'}
            checked={form.getFieldValue('isFilter')}
            text={'Is Filter'}
          />
          <FormCheckbox
            name={'onec'}
            checked={form.getFieldValue('onec')}
            text={'Выгружать в 1С'}
          />
          <FormCheckbox
            name={'showInTitle'}
            checked={form.getFieldValue('showInTitle')}
            text={'Показывать в заголовке товара'}
          />
        </Row>
        <Button
          htmlType={'submit'}
          style={{
            backgroundColor: '#428bca',
            color: '#fff',
            cursor: 'pointer',
            marginTop: 15,
          }}
        >
          {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить' : 'Обновить'}
        </Button>
      </Form>
    </div>
  );
};
