import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form } from 'antd';

import { statusShopOptions } from '../../../helpers/shopHelper';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import { transliterate } from '../../../helpers/productHelper';
import { SelectOptionHelper } from '../../../helpers/selectHelper';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import styles from './Shop.module.scss';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import { useTrimField } from '../../../hooks/useTrimField';
import { SUBMIT_METHOD_ADD } from '../../../types/constants';

interface ShopProps {
  titlePage: string;
  submitMethod: string;
}

export const Shop: React.FC<ShopProps> = ({ titlePage, submitMethod }) => {
  const { geoCities } = useTypedSelector((state) => state.geoCity);

  const { id } = useParams();
  const { request } = useHttp();

  const navigate = useNavigate();

  const { fetchGeoCitiesForSelect } = useActions();

  const citiesOptions = SelectOptionHelper(geoCities);

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchShop = await request({
          url: `${ApiUrl.SHOP}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          code: fetchShop.data?.code,
          title: fetchShop.data?.title,
          cityId: fetchShop.data?.cityId,
          status: fetchShop.data?.status.toString(),
          address: fetchShop.data?.address,
          phone: fetchShop.data?.phone,
          email: fetchShop.data?.email,
          icq: fetchShop.data?.icq,
          skype: fetchShop.data?.skype,
          dirName: fetchShop.data?.dirName,
          dirPhone: fetchShop.data?.dirPhone,
          dirEmail: fetchShop.data?.dirEmail,
          openingHoursMain: fetchShop.data?.openingHoursMain,
          openingHoursWeekend: fetchShop.data?.openingHoursWeekend,
          nameShipping: fetchShop.data?.nameShipping,
          alias: fetchShop.data?.alias,
          description: fetchShop.data?.description,
          map: fetchShop.data?.map,
          userAgent: fetchShop.data?.userAgent,
          prefixCredit: fetchShop.data?.prefixCredit,
          owner: fetchShop.data?.owner,
          creditStatus: fetchShop.data?.creditStatus,
          openingHoursCustom: fetchShop.data?.openingHoursCustom,
          staticIp: fetchShop.data?.staticIp,
          otpCode: fetchShop.data?.otpCode,
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchGeoCitiesForSelect();
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, []);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? { url: ApiUrl.SHOP, method: 'post', body: e }
          : { url: `${ApiUrl.SHOP}/${id}`, method: 'put', body: e };

      const response = await request(requestObject);
      switch (response.config.method) {
        case 'post':
          navigate('/shops/list');
          return NotificationAlert('success', 'Магазин добавлен');
        case 'put':
          return NotificationAlert('success', 'Магазин обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      alias: transliterate(event.target.value.toLowerCase()),
    });
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <FormInput name={'code'} label={'code'} placeholder={'code'} />
        <FormInput
          name={'title'}
          label={'Название'}
          placeholder={'Название'}
          required={true}
          onInput={handleTitleChange}
          maxLength={10}
          
        />
        <FormSelect
          name={'cityId'}
          label={'Город'}
          placeholder={'Выберите город'}
          options={citiesOptions}
          showSearch={true}
        />
        <FormSelect
          name={'status'}
          label={'Статус'}
          placeholder={'Выберите статус'}
          options={statusShopOptions}
        />
        <FormInput
          name={'address'}
          label={'Адрес'}
          placeholder={'Введите адрес'}
          maxLength={100}
        />
        <FormInput
          name={'phone'}
          label={'Телефон'}
          placeholder={'Введите телефон'}
          maxLength={20}
        />
        <FormInput
          name={'email'}
          label={'Email'}
          placeholder={'Введите email'}
          maxLength={20}
        />
        <FormInput name={'icq'} label={'icq'} placeholder={'Введите icq'} />
        <FormInput
          name={'skype'}
          label={'Skype'}
          placeholder={'Введите Skype'}
          maxLength={20}
        />
        <FormInput name={'dirName'} label={'Имя директора'} placeholder={'Введите имя директора'} />
        <FormInput
          name={'dirPhone'}
          label={'Телефон директора'}
          placeholder={'Введите телефон директора'}
          maxLength={20}
        />
        <FormInput
          name={'dirEmail'}
          label={'Email директора'}
          placeholder={'Введите email директора'}
          maxLength={20}
        />
        <FormInput
          name={'openingHoursMain'}
          label={'openingHoursMain'}
          placeholder={'openingHoursMain'}
          maxLength={50}
        />
        <FormInput
          name={'openingHoursWeekend'}
          label={'openingHoursWeekend'}
          placeholder={'openingHoursWeekend'}
          maxLength={50}
        />
        <FormInput
          name={'nameShipping'}
          label={'nameShipping'}
          placeholder={'nameShipping'}
          maxLength={50}
        />
        <FormInput name={'alias'} label={'alias'} placeholder={'alias'} maxLength={50} />
        <FormInput
          name={'description'}
          label={'Описание'}
          placeholder={'Описание производителя'}
          textArea={true}
        />
        <FormInput
          name={'map'}
          label={'Координаты'}
          placeholder={'Укажите координаты'}
        />
        <FormInput
          name={'userAgent'}
          label={'userAgent'}
          placeholder={'userAgent'}
          maxLength={100}
        />
        <FormInput
          name={'prefixCredit'}
          label={'prefixCredit'}
          placeholder={'prefixCredit'}
          maxLength={10}
        />
        <FormInput
          name={'owner'}
          label={'owner (number)'}
          placeholder={'owner'}
          type={'number'}
        />
        <FormInput
          name={'creditStatus'}
          label={'creditStatus (number)'}
          placeholder={'creditStatus'}
          type={'number'}
        />
        <FormInput
          name={'openingHoursCustom'}
          label={'openingHoursCustom'}
          placeholder={'openingHoursCustom'}
          textArea={true}
        />
        <FormInput
          name={'staticIp'}
          label={'staticIp'}
          placeholder={'staticIp'}
          maxLength={50}
        />
        <FormInput
          name={'otpCode'}
          label={'otpCode (number)'}
          placeholder={'otpCode'}
          type={'number'}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
