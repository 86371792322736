import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form } from 'antd';

import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import { statusGeoCityOptions } from '../../../helpers/geoCityHelper';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { SelectOptionHelper } from '../../../helpers/selectHelper';
import styles from './GeoCity.module.scss';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import { useTrimField } from '../../../hooks/useTrimField';
import { SUBMIT_METHOD_ADD } from '../../../types/constants';

interface GeoCityProps {
  titlePage: string;
  submitMethod: string;
}

export const GeoCity: React.FC<GeoCityProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { geoRegions } = useTypedSelector((state) => state.geoRegion);

  const { id } = useParams();
  const { request } = useHttp();

  const navigate = useNavigate();

  const { fetchGeoRegionsForSelect } = useActions();

  const regionsOptions = SelectOptionHelper(geoRegions);

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchGeoCity = await request({
          url: `${ApiUrl.GEO_CITY}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          regionId: fetchGeoCity.data?.regionId,
          name: fetchGeoCity.data?.name,
          code: fetchGeoCity.data?.code,
          status: fetchGeoCity.data?.status.toString(),
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchGeoRegionsForSelect();
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, []);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? { url: ApiUrl.GEO_CITY, method: 'post', body: e }
          : {
              url: `${ApiUrl.GEO_CITY}/${id}`,
              method: 'put',
              body: e,
            };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/content/geo/geo-city/list');
          return NotificationAlert('success', 'Город добавлен');
        case 'put':
          return NotificationAlert('success', 'Город обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form form={form} onFinish={onFinish} labelCol={{ span: 6 }}>
        <FormInput
          name={'name'}
          label={'Название города'}
          placeholder={'Название города'}
          required={true}
          labelCol={{ span: 24 }}
        />
        <FormSelect
          name={'regionId'}
          label={'Регион'}
          placeholder={'Выберите регион'}
          required={true}
          options={regionsOptions}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'code'}
          label={'Code'}
          placeholder={'Код города'}
          required={true}
          labelCol={{ span: 24 }}
        />
        <FormSelect
          name={'status'}
          label={'Статус'}
          placeholder={'Выберите статус'}
          required={true}
          options={statusGeoCityOptions}
          labelCol={{ span: 24 }}
        />
        <Form.Item>
          <Button style={{ marginTop: 16 }} type="primary" htmlType="submit">
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить город' : 'Обновить город'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
