import React from 'react';

import { Attribute } from '../Attribute';
import { SUBMIT_METHOD_ADD } from '../../../../types/constants';

export const AddAttribute: React.FC = () => {
  const titlePage = 'Добавление атрибута';
  const submitMethod = SUBMIT_METHOD_ADD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <Attribute {...props} />
    </>
  );
};
