import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, Upload } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { useActions } from '../../../hooks/useActions';
import { ApiUrl } from '../../../types/ApiUrl';
import styles from './Services.module.scss';
import { transliterate } from '../../../helpers/productHelper';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { createTree } from '../../../helpers/selectHelper';
import NotificationAlert from '../../../components/Notification';
import FormInput from '../../../components/FormItems/FormInput';
import FormTreeSelect from '../../../components/FormItems/FormTreeSelect';
import FormCheckbox from '../../../components/FormItems/FormCheckbox';
import { RolesInterceptor } from '../../../components/Interceptors/RolesInterceptor';
import { useTrimField } from '../../../hooks/useTrimField';
import { SUBMIT_METHOD_ADD, ROLES } from '../../../types/constants';

interface ServiceProps {
  titlePage: string;
  submitMethod: string;
}

export const Service: React.FC<ServiceProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { request } = useHttp();
  const { categories } = useTypedSelector((state) => state.category);
  const { id } = useParams();

  const [activeCategories, setActiveCategories] = useState([]);

  const { fetchCategoriesForSelect } = useActions();

  const categoriesTree = createTree(categories);

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchService = await request({
          url: `${ApiUrl.SERVICES}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          code: fetchService.data?.code,
          title: fetchService.data?.title,
          pricePerFloor: fetchService.data?.pricePerFloor,
          defaultPrice: fetchService.data?.defaultPrice,
          forAll: fetchService.data?.forAll,
          description: fetchService.data?.description,
          categories: fetchService.data?.categories.map(
            (category: any) => category.id,
          ),
        });
        setActiveCategories(
          fetchService.data?.categories.map((category: any) => ({
            categoryID: category.id,
            price: category.ServiceCategory.price,
          })),
        );
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchCategoriesForSelect();
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, [getData]);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? {
              url: ApiUrl.SERVICES,
              method: 'post',
              body: {
                ...e,
                categories: activeCategories,
              },
            }
          : {
              url: `${ApiUrl.SERVICES}/${id}`,
              method: 'put',
              body: {
                ...e,
                categories: activeCategories,
              },
            };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          return NotificationAlert('success', 'Категория добавлена');
        case 'put':
          return NotificationAlert('success', 'Категория обновлена');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  const onFieldsChange = async (e: any) => {
    if (e[0].name[0] && e[0].name[0] === 'categories')
      setActiveCategories(
        e[0].value.map((item: any) => ({
          categoryID: item,
          price: form.getFieldValue('defaultPrice'),
        })),
      );
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      slug: transliterate(event.target.value.toLowerCase()),
    });
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        form={form}
        labelCol={{ span: 6 }}
      >
        <RolesInterceptor roles={[ROLES.content]}>
          <FormInput
            labelCol={{ span: -10 }}
            name={'title'}
            label={'Наименование'}
            required={true}
            placeholder={'Наименование услуги'}
            onInput={handleNameChange}
          />
          <FormInput
            name={'code'}
            label={'Код'}
            placeholder={'Код'}
            required={true}
            maxLength={20}
          />
          <FormInput
            labelCol={{ span: -10 }}
            type={'number'}
            name={'defaultPrice'}
            label={'Стандартная цена'}
            placeholder={'Стандартная цена'}
          />
          <FormInput
            labelCol={{ span: -10 }}
            type={'number'}
            name={'pricePerFloor'}
            label={'Цена за этаж'}
            placeholder={'Цена за этаж'}
          />
          <FormInput
            labelCol={{ span: -10 }}
            name={'description'}
            label={'Описание'}
            placeholder={'Описание'}
          />
          <FormCheckbox
            name={'forAll'}
            text={'Для всех категорий'}
            checked={form.getFieldValue('forAll')}
          />
          <div>
            <FormTreeSelect
              name={'categories'}
              label={'Категории'}
              placeholder={'Выберите категорию'}
              showSearch={true}
              treeData={categoriesTree}
              multiple={true}
            />
          </div>
          {activeCategories.length > 0 && (
            <div className={styles['category-prices']}>
              <h4>Цены по категориям</h4>
              {activeCategories.map(
                (category: { categoryID: number; price: number }) => {
                  const activeCat = categories.find(
                    (cat) => cat.id === category.categoryID,
                  );
                  if (activeCat)
                    return (
                      <div key={category.categoryID}>
                        <span>{activeCat.name} - </span>
                        <input
                          id={category.categoryID.toString()}
                          value={category.price}
                          type="number"
                          onChange={(e: any) => {
                            const categoriesClone =
                              structuredClone(activeCategories);
                            const targetCategory: any = categoriesClone.find(
                              (cat: any) =>
                                cat.categoryID.toString() === e.target.id,
                            );
                            targetCategory!.price = e.target.value;
                            setActiveCategories(categoriesClone);
                          }}
                        ></input>
                      </div>
                    );
                },
              )}
            </div>
          )}
        </RolesInterceptor>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
