import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Row } from 'antd';

import { SelectOptionHelper } from '../../../../../helpers/selectHelper';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import { useHttp } from '../../../../../hooks/useHttp';
import { ApiUrl } from '../../../../../types/ApiUrl';
import { MultiSelectCustom } from '../../../../../components/AntdCustom/MultiSelectCustom';
import { InputCustom } from '../../../../../components/AntdCustom/InputCustom';
import { TextareaCustom } from '../../../../../components/AntdCustom/TextareaCustom';
import { SelectCustom } from '../../../../../components/AntdCustom/SelectCustom';
import { CheckboxCustom } from '../../../../../components/AntdCustom/CheckboxCustom';
import FormSelect from '../../../../../components/FormItems/FormSelect';
import FormTreeSelect from '../../../../../components/FormItems/FormTreeSelect';

interface AttributesProps {
  productId?: string;
  form: any;
  currentCategory: number[] | null;
  setCurrentCategory: (val: number[] | null) => void;
  categoryTree: any;
}

export const Attributes: React.FC<AttributesProps> = ({
  productId,
  form,
  currentCategory,
  setCurrentCategory,
  categoryTree,
}) => {
  const { types } = useTypedSelector((state) => state.type);
  const [attributes, setAttributes] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);

  const { fetchTypes } = useActions();

  const { request } = useHttp();

  useEffect(() => {
    if (!currentCategory || !currentCategory.length) {
      form.setFieldValue('attributeTypeProduct', undefined);
      setAttributes([]);
    }
  }, [currentCategory]);

  const getProductAttributes = async (value: number) => {
    try {
      const response = await request({
        url: `${ApiUrl.GET_ALL_PRODUCT_ATTRIBUTES}/${value}?productId=${productId}`,
        method: 'get',
      });
      console.log(response.data);
      setAttributes(response.data.attributes);
      if (response.data.values.length !== 0) {
        setAttributeValues(response.data.values);
      }
    } catch (e) {}
  };

  const typeProductId = form.getFieldsValue().attributeTypeProduct;

  useEffect(() => {
    fetchTypes();
    if (typeProductId) {
      getProductAttributes(typeProductId);
    }
  }, [typeProductId]);

  const renderAttribute = (attribute: any) => {
    const stateValue: any = attributeValues.filter((value: any) => {
      return value.attributeId === attribute.id;
    });
    switch (attribute.type) {
      case 0:
        return (
          <TextareaCustom
            value={
              stateValue.length !== 0 ? stateValue[0].textValue : undefined
            }
            form={form}
            name={attribute.id}
          />
        );
      case 1:
        return (
          <InputCustom
            value={
              stateValue.length !== 0 ? stateValue[0].stringValue : undefined
            }
            name={attribute.id}
            form={form}
            type={'string'}
          />
        );
      case 2:
        return (
          <SelectCustom
            value={
              stateValue.length !== 0 ? stateValue[0].optionValue : undefined
            }
            options={attribute.options.map((option: any) => {
              return {
                label: option.value,
                value: option.id,
              };
            })}
            name={attribute.id}
            form={form}
          />
        );
      case 3:
        return (
          <CheckboxCustom
            value={stateValue.length !== 0 ? stateValue[0].numberValue : false}
            name={attribute.id}
            form={form}
          />
        );
      case 4:
        return (
          <MultiSelectCustom
            name={attribute.id}
            value={stateValue.length !== 0 ? stateValue : []}
            options={attribute.options.map((option: any) => {
              return {
                label: option.value,
                value: option.id,
              };
            })}
            form={form}
          />
        );
      case 6:
        return (
          <InputCustom
            value={
              stateValue.length !== 0 ? stateValue[0].numberValue : undefined
            }
            name={attribute.id}
            form={form}
            type={'number'}
          />
        );
    }
  };

  return (
    <div>
      <FormTreeSelect
        name={'productCategory'}
        label={'Категория'}
        placeholder={'Выберите категорию'}
        showSearch={true}
        multiple={true}
        required={true}
        treeData={categoryTree}
        onChange={(cat) => setCurrentCategory(cat)}
        labelCol={{ span: 3 }}
      />
      <FormSelect
        name={'attributeTypeProduct'}
        label={'Тип'}
        placeholder={'Выберите тип'}
        options={
          currentCategory
            ? SelectOptionHelper(
                types.filter((type) =>
                  currentCategory.includes(type.categoryId),
                ),
              )
            : []
        }
        required={true}
        disabled={!Boolean(currentCategory) || currentCategory?.length === 0}
        showSearch={true}
        onChange={(value: number) => {
          if (!value) return setAttributes([]);
          return getProductAttributes(value);
        }}
        labelCol={{ span: 3 }}
      />
      {attributes.map((group: any) => {
        return (
          <div
            key={group.group.id}
            style={{
              fontSize: 22,
              textAlign: 'left',
              fontWeight: 'bold',
            }}
          >
            {group.group.title}
            <hr />
            {Object.values(group.attributes).map((attribute: any) => {
              return (
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 'normal',
                  }}
                  key={attribute.id}
                >
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={10}>
                      {attribute.title}
                      {!!attribute.unit && (
                        <span
                          style={{ fontWeight: 'bold' }}
                        >{` (${attribute.unit})`}</span>
                      )}
                    </Col>
                    <Col span={10}>{renderAttribute(attribute)}</Col>
                    <Col span={1} title="Показывать в заголовке товара">
                      <CheckboxCustom
                        value={attribute.showInTitle}
                        form={form}
                        name={attribute.id}
                        fieldName={`showInTitle`}
                      />
                    </Col>
                    <Col span={3} title="Порядок сортировки в заголовке товара">
                      <InputCustom
                        value={attribute.sortTitle}
                        name={attribute.id}
                        form={form}
                        type={'number'}
                        fieldName={`sortTitle`}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
