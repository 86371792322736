import React from 'react';

import { AttributeGroup } from '../AttributeGroup';
import { SUBMIT_METHOD_ADD } from '../../../../types/constants';

export const AddAttributeGroup: React.FC = () => {
  const titlePage = 'Добавление группы атрибутов';
  const submitMethod = SUBMIT_METHOD_ADD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <AttributeGroup {...props} />
    </>
  );
};
