import React, { useCallback, useEffect } from 'react';
import { Button, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import styles from './Payment.module.scss';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import { statusPaymentOptions } from '../../../helpers/paymentHelper';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import { useTrimField } from '../../../hooks/useTrimField';
import { SUBMIT_METHOD_ADD } from '../../../types/constants';

interface PaymentProps {
  titlePage: string;
  submitMethod: string;
}

export const Payment: React.FC<PaymentProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { id } = useParams();
  const { request } = useHttp();

  const navigate = useNavigate();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchPayment = await request({
          url: `${ApiUrl.PAYMENT}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          name: fetchPayment.data?.name,
          buttonName: fetchPayment.data?.buttonName,
          module: fetchPayment.data?.module,
          status: fetchPayment.data?.status.toString(),
          description: fetchPayment.data?.description,
          position: fetchPayment.data?.position,
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, [getData]);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? { url: ApiUrl.PAYMENT, method: 'post', body: e }
          : { url: `${ApiUrl.PAYMENT}/${id}`, method: 'put', body: e };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/services/payment/payment/list');
          return NotificationAlert('success', 'Способ оплаты добавлен');
        case 'put':
          return NotificationAlert('success', 'Способ оплаты обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <FormInput
          name={'name'}
          label={'Название'}
          placeholder={'Способ оплаты'}
          required={true}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'module'}
          label={'Название способа оплаты в базе данных'}
          placeholder={'Пример: "paykeeper"'}
          required={true}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'buttonName'}
          label={'Название кнопки оформления заказа при оформлении заказа'}
          placeholder={'Название кнопки'}
          labelCol={{ span: 24 }}
        />
        <FormSelect
          name={'status'}
          label={'Статус'}
          placeholder={'Выберите статус'}
          required={true}
          options={statusPaymentOptions}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'description'}
          label={'Описание'}
          placeholder={'Описание способа'}
          textArea={true}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'position'}
          label={'Позиция'}
          placeholder={'Позиция при сортировке'}
          textArea={true}
          labelCol={{ span: 24 }}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
