import { ApiUrl } from '../../../types/ApiUrl';
import { Button, Input } from 'antd';
import styles from './ManualRequests.module.scss';
import { useHttp } from '../../../hooks/useHttp';
import NotificationAlert from '../../../components/Notification';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useState } from 'react';

export const ManualRequests = () => {
  const { isAdmin } = useTypedSelector((state) => state.auth);
  const { request } = useHttp();
  const [percent, setPercent] = useState(70);
  const [percent2, setPercent2] = useState(70);
  const [productId, setProductId] = useState<string | number>('');

  async function launchPriceUpdate() {
    if (!isAdmin) return;

    try {
      await request({
        url: ApiUrl.ONE_C_PRICES,
        method: 'get',
      });

      NotificationAlert('success', 'Обновление цен началось');
    } catch (error: any) {
      NotificationAlert(
        'error',
        error?.response?.data?.message || 'Ошибка обновления цен',
      );
    }
  }

  async function sendTypesAttributes() {
    if (!isAdmin) return;

    try {
      const response = await request({
        url: ApiUrl.PRODUCT + '/type/empty',
        method: 'get',
        params: {
          proportion: percent
        }
      });

      if (response.data)
        NotificationAlert('success', 'Список аттрибутов отправлен на почту ' + response.data);
    } catch (error: any) {
      NotificationAlert(
        'error',
        error?.response?.data?.message || 'Ошибка',
      );
    }
  }

  async function sendProductAttributes() {
    if (!isAdmin) return;

    try {
      const response = await request({
        url: ApiUrl.PRODUCT + '/scaner',
        method: 'get',
        params: {
          proportion: percent2
        }
      });

      if (response.data)
        NotificationAlert('success', 'Список товаров отправлен на почту ' + response.data);
    } catch (error: any) {
      NotificationAlert(
        'error',
        error?.response?.data?.message || 'Ошибка',
      );
    }
  }

  async function sendProductDescriptionFileToTG() {
    if (!isAdmin) return;

    try {
      const response = await request({
        url: ApiUrl.ONE_C_PRODUCT_DESCRIPTION + '/' + productId,
        method: 'get',
      });

      if (response)
        NotificationAlert('success', response.data);
    } catch (error: any) {
      console.log('sendProductDescriptionFileToTG catch error', error);

      NotificationAlert(
        'error', 'Не удалось создать файл',
      );
    }
  }

  function getTargetYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const yearMinusTwo = currentYear - 2;
    return yearMinusTwo;
  }

  return (
    <div className={styles.manualRequests}>
      <h4>1. Запуск обновления цен в базе данных</h4>
      <Button type="primary" htmlType="button" onClick={launchPriceUpdate}>
        Запустить
      </Button>

      <h4>2. Удалить из базы данных отмененные ЗАКАЗЫ до {getTargetYear()} (не работает на данный момент)</h4>
      <Button type="primary" htmlType="button">
        Запустить
      </Button>

      <h4>
        3. Удалить из базы данных отмененные ОПЛАТЫ до {getTargetYear()} (не
        работает на данный момент)
      </h4>
      <Button type="primary" htmlType="button">
        Запустить
      </Button>

      <h4>
        4. Выгрузить из базы атрибуты товаров, которые заполнены менее чем у
        указанного процента товаров
      </h4>

      <div className={styles.attributes}>
        <label>Процент незаполненных атрибутов</label>
        <Input
          value={percent}
          type="number"
          onChange={(e) => {
            let value = Number(e.target.value);
            if (value < 0) value = 0;
            if (value > 100) value = 100;
            setPercent(value);
          }}
        ></Input>
      </div>
      <Button type="primary" htmlType="button" onClick={sendTypesAttributes}>
        Запустить
      </Button>

      <h4>
        5. Выгрузить из базы товары с группировкой по категориям, атрибуты которых заполнены меньше
        указанного процента
      </h4>

      <div className={styles.attributes}>
        <label>Процент незаполненных атрибутов</label>
        <Input
          value={percent2}
          type="number"
          onChange={(e) => {
            let value = Number(e.target.value);
            if (value < 0) value = 0;
            if (value > 100) value = 100;
            setPercent2(value);
          }}
        ></Input>
      </div>
      <Button type="primary" htmlType="button" onClick={sendProductAttributes}>
        Запустить
      </Button>

      <h4>
        5. Выгрузить файл описания товара по его id
      </h4>

      <div className={styles.attributes}>
        <label>id товара</label>
        <Input
          value={productId}
          type="number"
          onChange={(e) => {
            const value = Number(e.target.value);
            setProductId(value);
          }}
        ></Input>
      </div>
      <Button type="primary" htmlType="button" onClick={sendProductDescriptionFileToTG}>
        Получить файл в телеграме
      </Button>
    </div>
  );
};
