import React from 'react';

import { Category } from '../Category';
import { SUBMIT_METHOD_ADD } from '../../../../types/constants';

export const AddCategory: React.FC = () => {
  const titlePage = 'Добавление категории';
  const submitMethod = SUBMIT_METHOD_ADD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <Category {...props} />
    </>
  );
};
