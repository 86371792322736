import React, { useCallback, useEffect } from 'react';
import { Button, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import styles from './MenuItem.module.scss';
import { statusMenuOptions } from '../../../helpers/menuHelper';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import {
  createTreeMenu,
  SelectOptionHelper,
} from '../../../helpers/selectHelper';
import { useActions } from '../../../hooks/useActions';
import FormSelect from '../../../components/FormItems/FormSelect';
import FormTreeSelect from '../../../components/FormItems/FormTreeSelect';
import FormInput from '../../../components/FormItems/FormInput';
import { useTrimField } from '../../../hooks/useTrimField';
import { SUBMIT_METHOD_ADD } from '../../../types/constants';

interface MenuItemProps {
  titlePage: string;
  submitMethod: string;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { menus } = useTypedSelector((state) => state.menu);
  const { menuItems } = useTypedSelector((state) => state.menuItem);

  const navigate = useNavigate();

  const { fetchMenus, fetchMenuItemsForSelect } = useActions();

  const menuItemTree = createTreeMenu(menuItems);

  const { id } = useParams();
  const { request } = useHttp();

  const menuOptions = SelectOptionHelper(menus);

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchMenuItem = await request({
          url: `${ApiUrl.GET_ONE_MENU_ITEM}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          menuId: fetchMenuItem.data?.menuId,
          parentId:
            Number(fetchMenuItem.data?.parentId) === 0
              ? null
              : fetchMenuItem.data?.parentId,
          title: fetchMenuItem.data?.title,
          status: fetchMenuItem.data?.status.toString(),
          href: fetchMenuItem.data?.href,
          sort: fetchMenuItem.data?.sort,
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchMenuItemsForSelect();
    fetchMenus({ limit: 0 });
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, [getData]);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? { url: ApiUrl.MENU_ITEM, method: 'post', body: e }
          : { url: `${ApiUrl.MENU_ITEM}/${id}`, method: 'put', body: e };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/menu/menu-item/list');
          return NotificationAlert('success', 'Пункт меню добавлен');
        case 'put':
          return NotificationAlert('success', 'Пункт меню обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <FormSelect
          name={'menuId'}
          label={'Меню'}
          placeholder={'Выберите меню'}
          options={menuOptions}
          required={true}
          labelCol={{ span: 24 }}
        />
        <FormTreeSelect
          name={'parentId'}
          label={'Родитель'}
          placeholder={'Родитель'}
          treeData={menuItemTree.length > 0 ? menuItemTree : undefined}
          showSearch={true}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'title'}
          label={'Заголовок'}
          placeholder={'Заголовок'}
          required={true}
          labelCol={{ span: 24 }}
          maxLength={150}
        />
        <FormSelect
          name={'status'}
          label={'Статус'}
          placeholder={'Выберите статус'}
          options={statusMenuOptions}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'href'}
          label={'Адрес'}
          placeholder={'Адрес'}
          required={true}
          labelCol={{ span: 24 }}
          maxLength={150}
        />
        <FormInput
          name={'sort'}
          label={'Sort (number)'}
          placeholder={'Sort'}
          type={'number'}
          labelCol={{ span: 24 }}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
