import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal, Upload } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { useActions } from '../../../hooks/useActions';
import { ApiUrl } from '../../../types/ApiUrl';
import styles from './Category.module.scss';
import { transliterate } from '../../../helpers/productHelper';
import { statusCategoryOptions } from '../../../helpers/categoryHelper';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { createTree } from '../../../helpers/selectHelper';
import NotificationAlert from '../../../components/Notification';
import { useHttpUploadAntd } from '../../../hooks/useHttpUploadAntd';
import FormInput from '../../../components/FormItems/FormInput';
import FormTreeSelect from '../../../components/FormItems/FormTreeSelect';
import FormSelect from '../../../components/FormItems/FormSelect';
import FormCheckbox from '../../../components/FormItems/FormCheckbox';
import { RolesInterceptor } from '../../../components/Interceptors/RolesInterceptor';
import { useTrimField } from '../../../hooks/useTrimField';
import { SUBMIT_METHOD_ADD, ROLES } from '../../../types/constants';

interface CategoryProps {
  titlePage: string;
  submitMethod: string;
}

export const Category: React.FC<CategoryProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { request } = useHttp();
  const { requestUpload } = useHttpUploadAntd();
  const { categories } = useTypedSelector((state) => state.category);
  const { id } = useParams();

  const navigate = useNavigate();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const { fetchCategoriesForSelect } = useActions();

  const parentTree = createTree(categories);

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchCategory = await request({
          url: `${ApiUrl.CATEGORY}/${id}`,
          method: 'get',
        });

        if (fetchCategory.data?.image) {
          setFileList([
            {
              uid: id,
              name: fetchCategory.data?.image,
              status: 'done',
              url: `${ApiUrl.FILE}/${fetchCategory.data?.image}?dir=store,category&root=0`,
            },
          ]);
        }

        form.setFieldsValue({
          name: fetchCategory.data?.name,
          parentId: fetchCategory.data?.parentId,
          description: fetchCategory.data?.description,
          shortDescription: fetchCategory.data?.shortDescription,
          slug: fetchCategory.data?.slug,
          metaTitle: fetchCategory.data?.metaTitle,
          metaDescription: fetchCategory.data?.metaDescription,
          metaKeywords: fetchCategory.data?.metaKeywords,
          metaCanonical: fetchCategory.data?.metaCanonical,
          status: fetchCategory.data?.status.toString(),
          title: fetchCategory.data?.title,
          imageAlt: fetchCategory.data?.imageAlt,
          imageTitle: fetchCategory.data?.imageTitle,
          bankCode: fetchCategory.data?.bankCode,
          sort: fetchCategory.data?.sort,
          percentPrepayment: fetchCategory.data?.percentPrepayment,
          deliveryPrice: fetchCategory.data?.deliveryPrice,
          addDeliveryToPrice: Boolean(fetchCategory.data?.addDeliveryToPrice),
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchCategoriesForSelect();
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, [getData]);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleCancel = () => setPreviewOpen(false);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);
      
      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? {
              url: ApiUrl.CATEGORY,
              method: 'post',
              body: {
                ...e,
                percentPrepayment: e.percentPrepayment
                  ? e.percentPrepayment
                  : null,
                deliveryPrice: e.deliveryPrice ? e.deliveryPrice : 0,
                image: fileList[0]?.response?.name
                  ? fileList[0].response.name
                  : null,
              },
            }
          : {
              url: `${ApiUrl.CATEGORY}/${id}`,
              method: 'put',
              body: {
                ...e,
                percentPrepayment: e.percentPrepayment
                  ? e.percentPrepayment
                  : null,
                deliveryPrice: e.deliveryPrice ? e.deliveryPrice : 0,
                image: fileList[0]?.response?.name
                  ? fileList[0].response.name
                  : fileList[0]?.name
                  ? fileList[0].name
                  : null,
              },
            };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/categories/list');
          return NotificationAlert('success', 'Категория добавлена');
        case 'put':
          return NotificationAlert('success', 'Категория обновлена');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      slug: transliterate(event.target.value.toLowerCase()),
    });
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <RolesInterceptor roles={[ROLES.content]}>
          <FormInput
            name={'name'}
            label={'Наименование'}
            required={true}
            placeholder={'Наименование категории'}
            onInput={handleNameChange}
            maxLength={150}
          />
          <FormTreeSelect
            name={'parentId'}
            label={'Род. категория'}
            placeholder={'Выберите категорию'}
            showSearch={true}
            treeData={parentTree}
          />
          <FormInput
            name={'description'}
            label={'Описание'}
            placeholder={'Описание категории'}
            textArea={true}
          />
          <FormInput
            name={'shortDescription'}
            label={'Короткое описание'}
            placeholder={'Короткое описание'}
            textArea={true}
            maxLength={255}
          />
          <FormInput name={'slug'} label={'Slug'} placeholder={'slug'} />
        </RolesInterceptor>
        <RolesInterceptor roles={[ROLES.content, ROLES.seo]}>
          <FormInput
            name={'metaTitle'}
            label={'metaTitle'}
            placeholder={'metaTitle'}
            maxLength={250}
          />
          <FormInput
            name={'metaDescription'}
            label={'metaDescription'}
            placeholder={'metaDescription'}
            textArea={true}
            maxLength={250}
          />
          <FormInput
            name={'metaKeywords'}
            label={'metaKeywords'}
            placeholder={'metaKeywords'}
            textArea={true}
            maxLength={250}
          />
          <FormInput
            name={'metaCanonical'}
            label={'metaCanonical'}
            placeholder={'metaCanonical'}
            maxLength={250}
          />
        </RolesInterceptor>
        <RolesInterceptor roles={[ROLES.content]}>
          <FormSelect
            name={'status'}
            label={'Статус'}
            placeholder={'Выберите статус'}
            options={statusCategoryOptions}
          />
        </RolesInterceptor>
        <FormInput name={'title'} label={'title'} placeholder={'title'} maxLength={250} />
        <FormInput
          name={'imageAlt'}
          label={'imageAlt'}
          placeholder={'imageAlt'}
        />
        <FormInput
          name={'imageTitle'}
          label={'imageTitle'}
          placeholder={'imageTitle'}
        />
        <RolesInterceptor roles={[ROLES.content]}>
          <FormInput
            name={'bankCode'}
            label={'bankCode'}
            placeholder={'bankCode'}
            maxLength={20}
          />
          <FormInput
            name={'sort'}
            label={'sort (number)'}
            placeholder={'sort'}
            type={'number'}
          />
          <FormInput
            name={'percentPrepayment'}
            label={'Предоплата'}
            placeholder={'Введите процент предоплаты'}
            type={'number'}
          />
          <FormInput
            name={'deliveryPrice'}
            label={'Доставка'}
            placeholder={'Введите цену доставки'}
            type={'number'}
          />
          <FormCheckbox
            name={'addDeliveryToPrice'}
            text={
              <>
                <div>Добавлять стоимость доставки к общей сумме</div>
                <div>(Если не выбрано, то применять скидку на доставку)</div>
              </>
            }
            checked={Boolean(form.getFieldValue('addDeliveryToPrice'))}
          />
          {/*<ImgCrop*/}
          {/*  rotate*/}
          {/*  cropperProps={{ restrictPosition: false }}*/}
          {/*  minZoom={0.7}*/}
          {/*>*/}
          <Upload
            action="/api/category/file"
            listType="picture-card"
            fileList={fileList}
            customRequest={requestUpload}
            onChange={onChange}
            onPreview={onPreview}
            accept=".jpg,.jpeg,.png,.gif"
          >
            {fileList && fileList.length < 1 && '+ Загрузить изображение'}
          </Upload>
          {/*</ImgCrop>*/}
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </RolesInterceptor>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
