import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form } from 'antd';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import styles from '../../Attributes/Attribute/Attribute.module.scss';
import FormInput from '../../../components/FormItems/FormInput';
import { useTrimField } from '../../../hooks/useTrimField';
import { SUBMIT_METHOD_ADD } from '../../../types/constants';

interface AttributeGroupProps {
  titlePage: string;
  submitMethod: string;
}

export const AttributeGroup: React.FC<AttributeGroupProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { id } = useParams();
  const { request } = useHttp();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchAttributeGroup = await request({
          url: `${ApiUrl.GET_ONE_ATTRIBUTE_GROUP}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          name: fetchAttributeGroup.data.name,
          position: fetchAttributeGroup.data.position,
        });
      } catch (e) {}
    }
  }, [request]);

  useEffect(() => {
    getData();
  }, [getData]);

  const upload = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? {
              url: ApiUrl.ATTRIBUTE_GROUP,
              method: 'post',
              body: e,
            }
          : {
              url: `${ApiUrl.ATTRIBUTE_GROUP}/${id}`,
              method: 'put',
              body: e,
            };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/attributes/groups/list');
          return NotificationAlert('success', 'Группа атрибутов добавлена');
        case 'put':
          return NotificationAlert('success', 'Группа атрибутов обновлена');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form form={form} onFinish={upload}>
        <FormInput
          name={'name'}
          label={'Название'}
          placeholder={'название'}
          required={true}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'position'}
          label={'Позиция'}
          placeholder={'позиция'}
          labelCol={{ span: 24 }}
        />
        <Form.Item>
          <Button
            htmlType="submit"
            style={{
              backgroundColor: '#428bca',
              color: '#fff',
              cursor: 'pointer',
              marginTop: 15,
            }}
          >
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
